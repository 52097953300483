<template>
<div>

  <button class="vm-modal__close" @click="$emit('close')" :title="strings.close">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <div class="uk-box-shadow-medium uk-margin-bottom card ">
    <div class="uk-background-muted uk-padding body">
      <div class="category uk-margin uk-margin-remove-top uk-width-small" v-if="category">
        <img :src="_vaData.apiBase + category.logo" alt="category.title">
      </div>
      <h1 v-if="title" v-html="title" class="body__title"></h1>
      <div v-if="subtitle" v-html="subtitle" class="body__subtitle"></div>
      <div v-if="body">
        <vue-markdown>

          {{body}}
        </vue-markdown>
      </div>

    </div>
  </div>

  <Slide class="uk-background-default uk-box-shadow-medium uk-margin" v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
  <div class=" uk-background-default uk-padding">
    <div class="uk-grid uk-grid-small uk-grid-match uk-child-width-expand modal-functions" uk-grid>
      <CallToContact :referer="title">
        <button class="uk-button uk-width-1-1" :title="strings.get_info_cta">
          {{strings.get_info_cta}}
        </button>
      </CallToContact>
      <div v-if="product_finder_link">
        <a class="uk-button uk-width-1-1" :href="product_finder_link" target="_blank" title="Product Finder">Product Finder</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Slide from './Slide.vue'
import CallToContact from './CallToContact.vue';

// optional style for arrows & dots
export default {
  name: 'ModalDefault_mobile',
  data: function() {
    return {
      strings: this._vaData.strings,
    };
  },
  props: {
    title: String,
    subtitle: String,
    category: Object,
    body: String,
    product_finder_link: String,
    slides: Array
  },
  components: {
    Slide,

    CallToContact

  },
  methods: {
    loadIframe() {

    }

  },
  mounted() {

    // open all links in modal in new tab
    document.querySelectorAll('.c-va_modal__container a').forEach(($link) => {
      $link.addEventListener('click', e => {
        e.preventDefault();
        window.open(e.target.href);
        return;
      })
    })


  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.body__title {
    margin-bottom: 0;
}
.body__subtitle {
    @extend .uk-margin;
}

.modal-functions {
    .uk-button {
        padding: 0.5em 1em;
        line-height: 1.4;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
</style>
