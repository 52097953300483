<template>
<div>

  <span v-if="isOpen" class="toggle uk-position-top-left" :uk-icon="'icon:close; ratio: 2'" style="z-index: 2" uk-toggle="target: #offcanvas"></span>
  <span v-if="!isOpen" class="toggle uk-position-top-left " :uk-icon="'icon:menu; ratio: 2'" style="z-index: 2;" uk-toggle="target: #offcanvas"></span>

  <div id="offcanvas" uk-offcanvas="overlay: true; flip:false;mode:reveal;" ref="offcanvas">
    <div class="uk-offcanvas-bar">
      <div class="uk-margin-medium title">
        <h3 class="title__main" :class="strings.app_subtitle ? 'uk-margin-remove-bottom' : ''">{{strings.app_title}}</h3>
        <span lcass="title__sub" v-if="strings.app_subtitle">{{strings.app_subtitle}}</span>
      </div>
      <EntryBox :entries="locations" :title="strings.locations" :onEntryClick="goToLocation"></EntryBox>
      <EntryBox :entries="contents" :title="strings.contents" :onEntryClick="openContent"></EntryBox>

      <hr>
      <div class="contact-links">
        <ul class="uk-list">
          <li v-for="link in links" :key="link.id">
            <i v-if="link.icon" :style="{ 'background-image': 'url(' + _vaData.apiBase + link.icon + ')' }"></i>
            <a :href="link.href" :title="link.title" :target="'_' + link.target">{{link.title}}</a>
          </li>
          <li>
            <a @click="showContactForm" v-if="form" :title="form.title">
              <i :style="{ 'background-image': 'url(./contact_form_icon.svg)' }"></i>{{form.title}}</a>
          </li>
        </ul>
      </div>
      <hr>
      <LanguageSwitcher />
      <hr>
      <MetaLinks />
      <hr>
      <AppInfo :showTitle="true" />
    </div>
  </div>
</div>
</template>


<script>
import UIkit from 'uikit';
import EntryBox from './EntryBox.vue';
import MetaLinks from './MetaLinks.vue';
import AppInfo from './AppInfo.vue';
import LanguageSwitcher from './LanguageSwitcher.vue';

import {
  bus
} from '../main'
import ContactForm from './ContactForm.vue'

import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: 'Offcanvas',
  components: {
    LanguageSwitcher,
    EntryBox,
    MetaLinks,
    AppInfo
  },
  props: {

  },
  computed: {
    form: function() {
      return this._vaData.strings.forms.find(f => f.form_id === 'default_contact_form')
    }
  },
  data: function() {
    return {
      // https: //stackoverflow.com/a/63283176/1308363
      version: process.env.VUE_APP_VERSION,
      isOpen: false,
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      locations: this._vaData.locations.filter(l => l.show_in_menu),
      contents: this._vaData.contents,
      links: this._vaData.strings.contact_links,
      toggle: {
        color: {
          checked: this._vaData.settings.primary_color,
          unchecked: '#bf6363',
          disabled: this._vaData.settings.muted_color
        }
      }
    }
  },

  mounted() {

    UIkit.util.on('#offcanvas', 'show', () => {
      this.isOpen = true;
    });
    UIkit.util.on('#offcanvas', 'hide', () => {
      this.isOpen = false;
    });
  },
  methods: {
    getLinkText(href) {
      if (href.indexOf(':') > -1) {
        // works for mailto: and tel: hrefs
        return href.split(':')[1]
      } else {
        return href;
      }
    },
    openContent(id) {
      UIkit.offcanvas(this.$refs.offcanvas).hide();
      setTimeout(() => {
        bus.$emit('show_content', id);
      }, 500)
    },
    goToLocation(id) {
      UIkit.offcanvas(this.$refs.offcanvas).hide();
      bus.$emit('go_to_location', id);
    },
    showContactForm() {
      UIkit.offcanvas(this.$refs.offcanvas).hide();
      this.$modal.show(
        ContactForm, {
          form: this.form
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>
<style scoped  lang="scss">
a {
    color: $global-emphasis-color;

    &:hover {
        color: $global-emphasis-color;
        filter: brightness(110%);
    }
}

hr {
    color: $global-emphasis-color;
    opacity: 0.6;
}

h3,
h4,
h5 {
    text-transform: none;
}

.contact-links {
    a {
        white-space: nowrap;
    }

    i {
        height: 1em;
        width: 1em;
        display: inline-block;
        margin-right: 0.5em;

        background-size: contain;
        background-position: center;
        margin-bottom: -.2em;
    };
}

.toggle {
    @extend .uk-padding;
    color: $global-emphasis-color;
    z-index: 3 !important;
    cursor: pointer;

}

.uk-offcanvas-bar {
    @extend .uk-padding-small;
    background-color: #fff;
}

.title * {
    text-transform: none;
}

.title__sub {}
</style>
