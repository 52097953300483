<template>
<div>

  <button class="vm-modal__close" @click="$emit('close')" :title="strings.close">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>

  <div class="c-va_modal__container">

    <div class="uk-width-1-3 uk-margin-small-right uk-box-shadow-medium uk-flex uk-flex-column">
      <div class="uk-padding uk-background-muted uk-flex-1 body">

        <div class="category uk-margin uk-margin-remove-top uk-width-small" v-if="category">
          <img :src="_vaData.apiBase + category.logo" alt="category.title">
        </div>

        <h2 v-if="title" v-html="title" class="body__title"></h2>
        <div v-if="subtitle" v-html="subtitle" class="body__subtitle"></div>

        <div v-if="body">
          <vue-markdown>

            {{body}}
          </vue-markdown>
        </div>

      </div>
      <div class=" uk-background-default uk-padding modal-functions">
        <div class="uk-grid uk-grid-small uk-child-width-expand uk-grid-match" uk-grid>
          <CallToContact :referer="title">
            <button class="uk-button uk-width-1-1" :title="strings.get_info_cta">
              {{strings.get_info_cta}}
            </button>
          </CallToContact>
          <div v-if="product_finder_link">
            <a class="uk-button uk-width-1-1" :href="product_finder_link" target="_blank" title="Product Finder">Product Finder</a>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-width-expand uk-box-shadow-medium c-va_modal__main">

      <VueSlickCarousel v-bind="slickSettings" @beforeChange="beforeChangeCarousel" @afterChange="afterChangeCarousel" @init="onInitCarousel" ref="carousel">
        <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
      </VueSlickCarousel>
    </div>
  </div>

</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Slide from './Slide.vue'

import CallToContact from './CallToContact.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


// optional style for arrows & dots
export default {
  name: 'ModalDefault_desktop',
  data: function() {
    return {
      strings: this._vaData.strings,
      slickSettings: {
        "dots": true,
        "arrows": true
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    category: Object,
    body: String,
    product_finder_link: String,
    slides: Array,
    link_list: Array
  },
  components: {
    VueSlickCarousel,
    Slide,

    CallToContact
  },
  created() {


  },
  mounted() {

    // open all links in modal in new tab
    document.querySelectorAll('.c-va_modal__container a').forEach(($link) => {
      $link.addEventListener('click', e => {
        e.preventDefault();
        window.open(e.target.href);
        return;
      })
    })
    document.querySelectorAll('.c-va_modal__container video').forEach(($video) => {

      // hide controls of all videos
      $video.controls = "";
    });
    if (document.querySelector('.slick-current video')) {
      document.querySelector('.slick-current video').controls = "controls"
    }
  },
  methods: {
    onInitCarousel() {},
    afterChangeCarousel() {


      const $currentSlide = this.$refs.carousel.$el.querySelector('.slick-current');
      const $video = $currentSlide.querySelector('video');
      if ($video) {
        // show controls
        $video.controls = "controls";
      }
    },
    beforeChangeCarousel() {

      const $currentSlide = this.$refs.carousel.$el.querySelector('.slick-current');
      const $video = $currentSlide.querySelector('video');
      const $iframe = $currentSlide.querySelector('iframe');
      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";
      }
      if ($iframe) {
        const iframeSrc = $iframe.src;
        $iframe.src = iframeSrc;
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.body__title {
    margin-bottom: 0;
	font-size: 1.8rem;
	@media (max-width: 1100px) {
        font-size: 1.6rem;
    }
	@media (max-width: 960px) {
        font-size: 1.8rem;
    }
}
.body__subtitle {
    @extend .uk-margin-bottom;

}
.modal-functions {

    .uk-button {
        background: transparent;
        border: 1px solid $global-secondary-background !important;
        color: $global-secondary-background !important;
        padding: 0.5em 1em;
        transition: all 0.2s;
        line-height: 1.4;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.2em 0.5em;

        &:hover {
            background::$global-secondary-background;
            color: #fff !important;
        }

    }

}
</style>
