<template>
<div class="videoapp">
  <notifications position="bottom right" />

  <div v-if="isError" class="videoapp__error">
    <!-- ERROR -->
    <span class="emoji">😑</span>
    <h1>We are sorry!<br>
      Something went wrong.</h1>
    <div class="uk-alert-danger" uk-alert>
      {{isError}}
    </div>
    <p>If this error persists, please contact us.</p>
    <p>
      <a href="mailto:apps@visionsbox.de">apps@visionsbox.de</a>
      <br>
      <a href="tel:+49 7803 9789030">+49 7803 9789030</a>
    </p>
  </div>
  <div v-else-if="isLoading" class="videoapp__loading">
    <!-- LOADER -->
    <div>
      <i uk-spinner="ratio: 3"></i>
    </div>
    <p>Loading ...</p>
  </div>
  <template v-else>
    <div class="videoapp__main" id="app">

      <!-- APP -->
      <div id="page-wrap">
        <header class="videoapp__header">
          <Title :showBackBtn="showBackBtn"></Title>
          <a class="videoapp__logo" href="/" :title="strings.app_title" v-if="settings.logo">
            <img :src="_vaData.apiBase + settings.logo" :alt="strings.app_title">
          </a>
        </header>
        <Content />

        <div class="videoapp__videothing-wrap">
          <VideoThing :settings="vtSettings" />
        </div>
        <ContactMenu class="videoapp__contacts" position="bottom-right" />
        <MobileTitle :showBackBtn="showBackBtn" />
        <footer class="videoapp__footer">
          <div uk-grid class="uk-flex-bottom">
            <div class="uk-flex-1">
              <div uk-grid class="uk-grid-small">
                <UkNavDropdown v-if="locationsMenuEntries.length > 1" :entries="locationsMenuEntries" :title="strings.locations" @onEntryClick="onDropDownNavEntryClick" />
                <a href="/" class="uk-button uk-button-primary home-btn"></a>
              </div>
            </div>
            <div>
              <div uk-grid class="uk-grid-small">

              </div>
            </div>
          </div>
        </footer>

      </div>


    </div>
    <Offcanvas />

  </template>

</div>
</template>

<script>
import VideoThing from './components/videoThing/VideoThing.vue';
import UkNavDropdown from './components/UkNavDropdown.vue';
import Offcanvas from './components/Offcanvas.vue';
import Content from './components/content/index.vue';
import Title from './components/Title.vue';
import MobileTitle from './components/MobileTitle.vue';
import ContactMenu from './components/ContactMenu.vue';

import {
  bus
} from './main'


export default {
  name: 'App',
  components: {
    VideoThing,
    UkNavDropdown,
    Content,
    Title,
    MobileTitle,
    ContactMenu,
    Offcanvas
  },
  metaInfo() {
    return {
      title: this.strings ? this.strings.app_title : 'Loading ...'
    }
  },
  data: function() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      isLoading: true,
      isError: false,
      vtSettings: false,
      locationTitle: false,
      locationHotspots: false,
      showBackBtn: false
    };
  },
  computed: {
    contentMenuEntries: function() {
      return this._vaData.contents ? this._vaData.contents.filter(c => c.show_in_menu) : false;
    },
    locationsMenuEntries: function() {
      return this._vaData.locations ? this._vaData.locations.filter(l => l.show_in_menu) : false;
    }
  },
  watch: {
    // https://stackoverflow.com/a/46331968/1308363
    'settings.show_hotspots': {
      handler(val) {
        if (val) {
          bus.$emit('show_hotspots');
        } else {
          bus.$emit('hide_hotspots');
        }
      }
    },
    'settings.show_navigation': {
      handler(val) {
        if (val) {
          bus.$emit('show_navigation');
        } else {
          bus.$emit('hide_navigation');
        }
      }
    }
  },
  methods: {
    async fetchData() {

      const apiBase = process.env.VUE_APP_API_BASE;
      this.isLoading = true;

      console.log('🔄 Loading data ...')
      const availableLanguages = ['de', 'en', 'fr'];
      const params = new URLSearchParams(window.location.search);
      const langParameter = params.get('lang');
      let lang = 'en';

      if (langParameter) {
        lang = (availableLanguages.indexOf(langParameter) > -1) ? langParameter : lang;
      } else {
        // try user lang
        var userLang = navigator.language || navigator.userLanguage;
        userLang = userLang.split('-')[0];

        lang = (availableLanguages.indexOf(userLang) > -1) ? userLang : lang;
      }

      console.log('🏳️‍🌈 Language: ' + lang);
      // set document lang (html attribute)
      document.documentElement.lang = lang.split('-')[0];
      this._vaData.currentLanguage = lang;


      await Promise.all([
          fetch(apiBase + '/locations/' + lang),
          fetch(apiBase + '/destinations'),
          fetch(apiBase + '/settings'),
          fetch(apiBase + '/products/' + lang),
          fetch(apiBase + '/strings/' + lang)
        ])
        .then(data => {

          let failedRequests = data.filter(d => d.ok === false);

          if (failedRequests.length) {
            let error = 'Some API calls failed.';
            throw Error(error)
          }

          return data
        })
        .then((data) => {
          return Promise.all(data.map(d => d.json()))
        })
        .then(data => {

          this._vaData.locations = this.locations = data[0];
          this._vaData.destinations = this.destinations = data[1];
          this._vaData.settings = this.settings = data[2];
          this._vaData.contents = this.contents = data[3];
          this._vaData.strings = this.strings = data[4];


          console.log('👨🏼‍🔧 Settings', this._vaData.settings)
          console.log('🏝️ Locations: ', this._vaData.locations)
          console.log('✈️ Destinations: ', this._vaData.destinations)
          console.log('📚 Contents: ', this._vaData.contents)
          console.log('📄 Strings: ', this._vaData.strings)

          if (!this.locations.length) {
            // this.isError = 'No location found!';
            throw new Error('No location found!')
          }

          this.init();

        })
        .catch(err => {
          // api not responding
          this.isError = err;

        }).finally(() => {
          this.isLoading = false;
        })

    },
    init() {

      console.log('📽️ Starting videoThing')
      const params = new URLSearchParams(window.location.search);
      const defaultStartLocation = this.settings.start_location ? this.settings.start_location : this._vaData.locations[0].id;
      const startLocation = params.get('location') ? parseInt(params.get('location')) : defaultStartLocation;

      this.vtSettings = {
        startLocation: startLocation,
        screensaver: null,
        preloadDestinationsAtStart: false,
        assetBase: process.env.VUE_APP_API_BASE,
        transitionOverlay: {
          color: '#fff',
          transitionDuration: 1000
        },
      }

      // bus.$emit('location_reached', this.settings.start_location, []);


    },
    onDropDownNavEntryClick(entry) {

      switch (entry.type) {
        case 'default':
          bus.$emit('show_content', entry.id);
          break;
        case 'hotspot':
          bus.$emit('show_content', entry.target_content.id);
          break;
        case 'menu':
          bus.$emit('show_button_menu', null, entry.menu_data);
          break;
        default:
          bus.$emit('go_to_location', entry.id);
          break;
      }
    }

  },
  async created() {

    bus.$on('location_reached', (id, history) => {
      const location = this._vaData.locations.find(l => l.id === id)
      console.info(`➡️ You reached location: ${location.title} (ID: ${id})`, location);
      this.locationTitle = location.title

      this.locationHotspots = location.buttons.filter(b => b.type === 'hotspot');
      setTimeout(() => {
        this.showBackBtn = history.length > 1 && location.show_back_btn;
      }, 1)

      const params = new URLSearchParams(window.location.search);
      params.set('location', id)
      window.history.replaceState({}, '', `?${params}`);
    })

    bus.$on('location_left', (id) => {
      const location = this._vaData.locations.find(l => l.id === id)
      console.info(`⬅️ You left location: ${location.title} (ID: ${id})`);
      this.locationHotspots = false;
      this.showBackBtn = false;
    })

    await this.fetchData();

    const params = new URLSearchParams(window.location.search);
    const defaultStartContent = this.settings.start_content ? this.settings.start_content : null;
    const startContent = params.get('content') ? parseInt(params.get('content')) : defaultStartContent;

    if (startContent) {
      bus.$emit('show_content', startContent);
    }
  },

  mounted() {

  }
}
</script>

<style lang="scss">
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";
@import './videoapp.scss';

body {
    &.is-mobile {
        @media screen and (orientation: landscape) and (max-height: 720px) {
            position: relative;

            &:before {
                content: '';
                z-index: 99999;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: $global-emphasis-color;
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(50% - 100px);
                left: calc(50% - 100px);
                width: 200px;
                height: 200px;
                background-image: url("./assets/rotate.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                z-index: 99999;
            }
        }
    }
}

.home-btn {
    width: 2em;
    margin-left: 0.5em;
    background-image: url("./assets/haus.svg");
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
