<template>
<div @click="showContactForm()">
  <slot></slot>
</div>
</template>

<script>
import ContactForm from '../ContactForm.vue'
export default {
  name: 'CallToContact',
  props: {
    referer: String
  },
  data: function() {
    return {
      form: this._vaData.strings.forms.find(f => f.form_id === 'default_contact_form'),
      strings: this._vaData.strings
    }
  },
  components: {

  },
  methods: {
    showContactForm() {

      this.form.referer = this.referer;
      this.$modal.show(
        ContactForm, {
          form: this._vaData.strings.forms.find(f => f.form_id === 'default_contact_form'),
          strings: this._vaData.strings
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>
