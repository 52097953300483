<template>
<div class="title">
  <h1>{{strings.app_title}}</h1>
  <div class="">
    <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
      <div class="l-title" v-if="locationTitle">
        {{locationTitle}}
      </div>
    </transition>
  </div>
  <div class="uk-margin-top btns">
    <!-- <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
      <button class="back-btn" v-if="showBackBtn" @click="goBack()" style="transition-delay: .5s">
        <i uk-icon="chevron-left"></i> {{strings.back}}</button>
    </transition> -->

    <transition name="fade" enter-active-class="fadeInRight" leave-active-class="fadeOutRight">
      <button v-if="parentLocation" class="back-btn" @click="goToLocation(parentLocation.id)" :title="parentLocation.title">
        <i uk-icon="chevron-left"></i> {{strings.back}}
        <span>&#8205;</span>
        <!-- {{parentLocation.title}} -->
      </button>
    </transition>
  </div>
</div>
</template>
<script>
import {
  bus
} from '../main';

export default {
  name: 'Title',
  data() {
    return {
      strings: this._vaData.strings,
      locationTitle: null,
      parentLocation: null
    }
  },
  props: {
    showBackBtn: Boolean
  },
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit('go_back');
    },
    goToLocation: (id) => {

      bus.$emit('go_to_location', id);
    }
  },
  created() {

    // this.locationTitle = this._vaData.locations.find(l => l.id === this._vaData.settings.start_location).title;

    bus.$on('location_reached', (id) => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.locationTitle = location.title;
      this.parentLocation = location.parent_location;
    })

    bus.$on('location_left', () => {
      this.locationTitle = null;
      this.parentLocation = null;
    })

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";
.btns {
    display: flex;
}

.btn-parent {
    border: none;
    background-color: $global-emphasis-color;
    background-image: url("../assets/segment.svg");
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    line-height: 1.2;
    min-height: 40px;
    font-size: 13px;
    min-width: 3em;
    padding: 0.3em 0.6em;
    cursor: pointer;
}

.title {
    margin-top: 2px;
    @media (max-width: $breakpoint-medium) {
        display: none;
    }

}

h1 {

    margin: 0;
    font-size: 1.6em;
}

.l-title {

    overflow: hidden;
    border: none;
    font-size: 1.2em;
    color: $global-emphasis-color;

}

h2 {
    margin: 0;
    font-size: 1.4em;

}

.back-btn {
    @extend .uk-button;
    @extend .uk-button-primary;
    min-height: 40px;
    padding-left: 1em;
    padding-right: 1.5em;
    font-size: 13px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 0.5em;

    i {
        margin-right: 5px;
    };

}
</style>
