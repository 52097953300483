<template>
<div class="videothing">
  <!-- here -->
</div>
</template>

<script>
import VideoThing from 'videoThing';
import "../../../node_modules/videoThing/dist/videoThing.css";
import {
  bus
} from '../../main';

export default {
  name: 'VideoThing',
  props: {
    settings: Object
  },
  created() {
    bus.$on('go_back', () => {
      this.videoThing.goBack();
    })
    bus.$on('show_hotspots', () => {
      this.videoThing.showHotspots()
    })
    bus.$on('hide_hotspots', () => {
      this.videoThing.hideHotspots()
    })
    bus.$on('show_navigation', () => {
      this.videoThing.showNavigation()
    })
    bus.$on('hide_navigation', () => {
      this.videoThing.hideNavigation()
    })
    bus.$on('show_menus', () => {
      this.videoThing.showMenus()
    })
    bus.$on('hide_menus', () => {
      this.videoThing.hideMenus()
    })
    bus.$on('go_to_location', (id) => {
      this.videoThing.goToLocation(id)
    })
  },
  methods: {},
  mounted() {

    const elem = document.querySelector('.videothing');
    this.videoThing = new VideoThing(elem, this._vaData.locations, this._vaData.destinations, this.settings);

    this.videoThing.on('location_reached', (id) => {
      bus.$emit('location_reached', id, this.videoThing.history);

      // this.$nextTick(() => {
      //
      //   // if last location is new location, remove it from history
      //   const history = this.videoThing.history;
      //   if (history.length > 1 && history[history.length - 1] === history[history.length - 2]) {
      //     this.videoThing.history.pop();
      //   }
      // })

    });

    this.videoThing.on('location_left', (id) => {
      bus.$emit('location_left', id, this.videoThing.history);
    });

    this.videoThing.on('custom_button_clicked', (e, item) => {
      bus.$emit('show_content', item.target_product.id);
    });

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.videothing {

    $hotspot-background: $global-emphasis-color;
    $navigation-background: $global-emphasis-color;

    .controls {

        .button {
            padding: 0.5em 1.5em;
            background-color: $global-muted-color;
            user-select: none;

            content: "";

            &[class*="btn-dot"] {

                height: 40px;
                width: 40px;
                box-sizing: border-box;
                border-radius: 50%;
                position: relative;
                background: transparent !important;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-radius: 50%;
                    transform: scale(1);
                    animation: pulse 2s infinite;
                    background-color: $global-emphasis-color;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 50%;
                }

                .button__text {
                    display: none;
                }

            }
            @media (max-width: 1200px) {
                &.btn-dot--play {
                    margin-right: 10px !important;
                }
                &.btn-dot--info {
                    margin-left: 10px !important;
                }
            }
            @media (max-width: 960px) {
                &[class*="btn-dot"] {
                    height: 35px;
                    width: 35px;
                }
                &.btn-dot--play {
                    margin-right: 25px !important;
                }
                &.btn-dot--info {
                    margin-left: 25px !important;
                }
            }

            &.btn-dot--play:after {
                background-image: url("../../assets/play.svg");
            }
            &.btn-dot--plus:after {
                background-image: url("../../assets/plus.svg");
            }
            &.btn-dot--info:after {
                background-image: url("../../assets/info.svg");
            }

            &[class*="btn-square"] {

                height: 40px;
                width: 40px;
                box-sizing: border-box;
                border-radius: none;
                position: relative;
                background: transparent !important;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-radius: none;
                    // transform: scale(1);
                    // animation: pulse 2s infinite;
                    background-color: $global-emphasis-color;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 80%;
                }

                .button__text {
                    display: none;
                }

            }

            &.btn-square--left:after {
                background-image: url("../../assets/chevron-left.svg");
            }
            &.btn-square--right:after {
                background-image: url("../../assets/chevron-right.svg");
            }

            &[data-type="hotspot"] {

                background-color: $hotspot-background;
                color: #fff;

                &:hover {
                    background-color: $hotspot-background;
                    filter: brightness(120%);
                }
            }

            &[data-type="navigation"] {
                background-color: $navigation-background;
                color: #fff;
                @extend .uk-box-shadow-medium;
                &:hover {
                    background-color: $navigation-background;
                    filter: brightness(120%);

                }
            }

            // @media (max-width: $breakpoint-medium) {
            //     height: 35px;
            //     width: 35px;
            //     box-sizing: border-box;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //
            //     .button__text {
            //         display: none;
            //     }
            //
            //     &:after {
            //         content: '+';
            //         font-size: 20px;
            //         display: inline-block;
            //
            //     }
            // }

        }

    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 97, 174, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 97, 174, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 97, 174, 0);
        }
    }

}
</style>
